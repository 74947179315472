// @ts-ignore
import PhotoSwipeLightbox from 'photoswipe/lightbox'
// eslint-disable-next-line import/order
import React, {
  type FC,
  useEffect,
  useState,
} from 'react'

import 'photoswipe/style.css'

// eslint-disable-next-line import/order
import {Box} from "@mui/system";

// eslint-disable-next-line import/order
import {
  Checkbox,
  ImageList,
  Skeleton,
  Stack, Tooltip,
} from '@mui/material'

// eslint-disable-next-line import/order
import HideImageIcon from '@mui/icons-material/HideImage';

// eslint-disable-next-line import/order
import {GalleryImage} from 'shared/ui/PhotoSwipeGallery'

// eslint-disable-next-line import/order
import wretch from 'wretch'

// eslint-disable-next-line import/order
import Typography from '@mui/material/Typography'
import {selectInitialsDialogState, UnitsInitailsDialogState, useAppSelector} from "../../../../app/store";

// eslint-disable-next-line import/order

export interface InitialsGalleryImage extends GalleryImage {
  fileName?: string
  version?: number
  checked?: boolean
  id: number
}

interface InitialsGalleryProps {
  galleryID: string
  images: InitialsGalleryImage[]
  columns?: number
  onFileCheck: (fileId: number, value: boolean) => void,
  onSuccessLoad: (success: boolean) => void
}

const createImage = async (longUrl: any) => {
  const data = await wretch(longUrl).get().blob()
  const url = URL.createObjectURL(data)
  const img = new Image()
  img.src = url
  img.title = data.type

  return img
}

export const InitialsGallery: FC<InitialsGalleryProps> = ({
  galleryID,
  images,
  columns,
  onFileCheck,
  onSuccessLoad,
}) => {

  const [imgSet, setImgSet] = useState<HTMLImageElement[]>([])
  const [success, setSuccess] = useState<boolean | undefined>(undefined)
  const initialsUnitFiles: UnitsInitailsDialogState = useAppSelector(selectInitialsDialogState)

  const loadedImages: any[] = [];

  useEffect(() => {
    const getData = async () => {

      try {
        for (const image of images) {
          const [img] = await Promise.all([createImage(image.largeURL)]);
          loadedImages.push(img);
        }
        if(loadedImages.length === 0)
        {
          onSuccessLoad(false);
        }
        if(loadedImages.length > 0)
        {
          setImgSet(loadedImages);
          setSuccess(true);
          onSuccessLoad(true);
        }

      } catch (err) {
        setSuccess(false);
        setImgSet([]);
      }
    };
    getData();

  }, [images]);

  useEffect(() => {
    let lightbox: PhotoSwipeLightbox | null = new PhotoSwipeLightbox({
      gallery: `#${galleryID}`,
      childSelector: '.pswp-photo',
      pswpModule: () => import('photoswipe'),
      initialZoomLevel: 'fit',
    });
    lightbox?.init();

    return () => {
      lightbox?.destroy();
      lightbox = null;
    };
  }, []);

  if (!imgSet?.length) {
    return <Skeleton
      variant="rectangular"
    />
  }

  const handleDownload = (image: HTMLImageElement, fileName: string, event:  React.MouseEvent<HTMLImageElement, MouseEvent>) => {

    if(image.title === 'application/pdf')
    {
      event.preventDefault();
      event.stopPropagation();

      const newFileName = fileName.replace(/\.[^/.]+$/, ".jpg");

      const link = document.createElement('a');
      link.href = image.src;
      link.download = newFileName || 'image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <ImageList
      variant="quilted"
      cols={columns || 4}
      gap={4}
      id={galleryID}
    >
      {!success && <Typography>Error on load</Typography>}
      {success && imgSet?.map((curImage: HTMLImageElement, index) => {
        return (
          <Stack
            sx={{
              border: 1,
              borderColor: 'grey.500',
              borderRadius: '5px',
            }}
            key={galleryID + '-' + index}
            direction={'column'}
            alignContent={'center'}
          >
            <Box>
              {curImage?.width === 0 &&
                  (<HideImageIcon
                    color="primary"
                    sx={{ fontSize: 40}}
                  ></HideImageIcon>)
              }
              <a
                className={'pswp-photo'}
                href={curImage.src}
                data-pswp-width={curImage.width}
                data-pswp-height={curImage.height}
                key={galleryID + '-' + index}
                target="_blank"
                rel="noreferrer"
              >
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
                <img
                  src={curImage?.currentSrc || ''}
                  width={300}
                  alt={''}
                  loading={'lazy'}
                  onClick={(e) => handleDownload(curImage,
                    initialsUnitFiles.fileList?.find((f) => f.id == images[index]?.id)?.fileName??"",
                    e)}
                />
              </a>
            </Box>
            <Stack
              direction={'row'}
              spacing={1}
              alignContent={'center'}
              alignItems={'center'}
            >
              <Checkbox
                checked={initialsUnitFiles.fileList?.find((f) => f.id == images[index]?.id)?.checked}
                onChange={(event) => {
                  onFileCheck(images[index]?.id, event?.target?.checked)
                }
                }
                inputProps={{'aria-label': 'controlled'}}
              />
              <Tooltip title={images[index]?.fileName}>
                <Typography>{`${(images[index]?.fileName!.length <= 30 ?
                  images[index]?.fileName 
                  :  images[index]?.fileName!.substring(0,30)+"...")}`}</Typography>
              </Tooltip>

            </Stack>
          </Stack>
        )
      })
      }
    </ImageList>
  )
}
