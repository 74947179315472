import {type AuthenticationResult} from '@azure/msal-common'
import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  LinearProgress,
  Stack,
  Tooltip,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import FileSaver from 'file-saver'
import {useSnackbar} from 'notistack'
import  {
  type FC,
  useEffect,
  useState,
} from 'react'

import {
  changeChecked,
  changeCheckedAll,
  close,
  downloadInitials,
  selectInitialsDialogState,
  type UnitsInitailsDialogState,
  useAppDispatch,
} from 'app/store'
import {useAppSelector} from 'app/store/hooks'

import {
  InitialsGallery,
  InitialsGalleryImage,
} from 'features/common/showInitials/ui/InitialsGallery'

import {API_GATEWAY_ADDRESS} from 'shared/api/api'
import authService from 'shared/api/AuthService'
import {themeSpacingFromPixels} from 'shared/lib/workWithThemeSpacing'
import {type InitialsFile} from 'shared/model/contracts/InitialsFile'

import {setFilesLoaded, setLoaded} from "../../../app/store/units/initialsDialogSlice";

import {ModalImagePreview} from './ModalImagePreview'

const InitialsDialog: FC = () => {
  const dispatch = useAppDispatch()

  const initialsUnitFiles: UnitsInitailsDialogState = useAppSelector(selectInitialsDialogState)

  const open = initialsUnitFiles.open
  const [token, setToken] = useState<AuthenticationResult['accessToken']>()
  const [galleryData, setGalleryData] = useState<InitialsGalleryImage[]>([])

  useEffect(() => {
    const sToken = async () => {
      const t: AuthenticationResult = await authService.getIdentity()
      setToken(t.accessToken)
    }
    void sToken()
  }, [])
  const {
    enqueueSnackbar,
  } = useSnackbar()

  const handleCancelClick = () => {
    dispatch(close())
    dispatch(setLoaded(false))
  }

  const sortedFiles =
      galleryData === null
        ? galleryData
        : galleryData.sort((a, b) => {
          const firstExtensions = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'bmp', 'svg'];
          const secondExtensions = ['tif', 'tiff'];
          const thirdExtensions = ['doc', 'docx', 'xls', 'xlsx'];

          const extA = a.fileName?.split('.').pop()?.toLowerCase() ?? '';
          const extB = b.fileName?.split('.').pop()?.toLowerCase() ?? '';

          const getGroup = (ext: string) => {
            if (firstExtensions.includes(ext)) return 1;
            if (secondExtensions.includes(ext)) return 2;
            if (thirdExtensions.includes(ext)) return 3;
            return 4;
          };

          const groupA = getGroup(extA);
          const groupB = getGroup(extB);

          if (groupA !== groupB) {
            return groupA - groupB;
          }

          return extA.localeCompare(extB);
        });

  useEffect(() => {
    if (!open) {
      setGalleryData([])
      dispatch(setLoaded(false))
    } else {

      const galData: InitialsGalleryImage[] = initialsUnitFiles?.fileList?.map((initial: InitialsFile): InitialsGalleryImage => {
        return {
          largeURL: `${API_GATEWAY_ADDRESS}/ec/units/${initialsUnitFiles.unit.unitFileId}/files/initials/${initial.id}/file?token=${token}&width=400&height=400`,
          fileName: initial.fileName,
          version: initial.version,
          id: initial.id,
          checked: initial.checked,
        }
      }) || []

      setGalleryData(galData)
      dispatch(setLoaded(true))

    }
  }, [open])

  const handleFileCheck = (fileId: number, value: boolean) => {
    dispatch(changeChecked({
      fileId,
      value,
    }))
  }

  const handleCheckAll = (value: boolean) => {
    dispatch(changeCheckedAll(value))
  }

  const handleDownloadClick = async () => {
    enqueueSnackbar('Initials download started', {variant: 'info'})

    const selectedFiles = initialsUnitFiles.fileList?.filter((f) => f.checked) ?? []
    const content = await dispatch(
      downloadInitials({
        unitId: initialsUnitFiles?.unit?.unitFileId || 0,
        files: selectedFiles.map((f) => {
          return {
            fileId: f.id,
            fileName: f.fileName,
            version: f.version,
          }
        }),
      }),
    ).unwrap()

    enqueueSnackbar('Initials download succeed', {variant: 'success'})
    FileSaver.saveAs(content, initialsUnitFiles.unit?.oemArticle
      ? `${initialsUnitFiles.unit.oemArticle} Initials.zip`
      : 'Initials.zip')

  }

  const handleSuccessLoad = (success: boolean): void => {
    dispatch(setFilesLoaded(success))
  }

  return (
    <Dialog
      sx={{'& .MuiDialog-paper': {maxWidth: themeSpacingFromPixels(1100)}}}
      open={initialsUnitFiles.open}
    >
      <DialogTitle>Initials files</DialogTitle>
      <Divider/>
      <DialogContent>
        <Collapse in={!initialsUnitFiles.filesLoaded && galleryData.length === 0}>
          <Typography>{'There aren\'t initials for this unit'}</Typography>
        </Collapse>
        <Collapse in={!initialsUnitFiles.filesLoaded && galleryData.length > 0}>
          <LinearProgress color="primary"/>
        </Collapse>
        <Collapse in={initialsUnitFiles.filesLoaded}>
          <FormControlLabel
            control={
              <Stack
                direction={'row'}
                spacing={1}
                marginRight={2}
              >
                <Tooltip
                  title="Select all files"
                  placement="right"
                >
                  <Checkbox
                    sx={{marginLeft: 6}}
                    checked={initialsUnitFiles.fileList?.every((f) => f.checked)}
                    onChange={(event) => {
                      handleCheckAll(event.target.checked)
                    }}
                  />
                </Tooltip>
              </Stack>
            }
            label={'Select all elements'}
          />
          <InitialsGallery
            galleryID={'initials-gallery'}
            images={sortedFiles}
            columns={2}
            onFileCheck={handleFileCheck}
            onSuccessLoad={handleSuccessLoad}
          />
        </Collapse>
        <Collapse in={initialsUnitFiles.error?.message !== undefined}>
          <Typography color="error">
            {initialsUnitFiles.error?.message ?? 'An error occurred while fetching data. Please try again later'}
          </Typography>
        </Collapse>
        {initialsUnitFiles.fetchStatus !== 'pending' && initialsUnitFiles.fileList?.length !== 0 && (
          <Collapse in={!initialsUnitFiles.fileList?.some((f) => f.checked) && initialsUnitFiles.filesLoaded}>
            <Typography color="error">Select one or more documents</Typography>
          </Collapse>
        )}
      </DialogContent>
      <ModalImagePreview open={initialsUnitFiles.previewOpened}/>
      <Divider/>
      <DialogActions>
        <Stack
          direction={'row'}
          spacing={1}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownloadClick}
            disabled={
              initialsUnitFiles.downloadStatus === 'pending' ||
                            initialsUnitFiles.fetchStatus === 'pending' ||
                            !initialsUnitFiles.fileList?.some((f) => f.checked)
            }
            endIcon={initialsUnitFiles.downloadStatus === 'pending'
              ? <CircularProgress size={16}/>
              : <></>}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancelClick}
          >
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default InitialsDialog
